function cloudinaryImageResize(
  url: string,
  width: number | null = null,
  height: number | null = null
) {
  function insert(str: string, index: number, value: string) {
    return str.substring(0, index) + value + str.substring(index);
  }

  const precedingStr = 'image/upload/';
  let insertParamIndex = url.indexOf(precedingStr);
  if (insertParamIndex < 0) return url;
  insertParamIndex = insertParamIndex + precedingStr.length;

  // https://cloudinary.com/documentation/resizing_and_cropping
  // e.g. inserting 'f_auto,w_100,h_200/' after 'image/upload/'
  let newParams = 'f_auto';
  if (width) {
    newParams = `,w_${width}`;
    if (height) {
      newParams += `,h_${height}`;
    }
  }
  newParams += '/';
  return insert(url, insertParamIndex, newParams);
}

export default cloudinaryImageResize;
