import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import ApplicationUser from '@/models/auth/applicationUser';
import {
  AssetModalModule,
  NewAssetModalModule,
  PostModalModule,
  StickerModalModule,
  StoresModule,
  UiModule,
  CreateModule,
  CreatePostModule,
  CreateAssetModule,
} from '@/store';
import { UserModule } from '@/store/userModule';
import { AltDrawerType } from '@/constants';
import { UserStore } from '@/models/users/userStore';

export abstract class LoggedInComponentBase extends Vue {
  protected assetModalModule = getModule(AssetModalModule);
  protected newAssetModalModule = getModule(NewAssetModalModule);
  protected postModalModule = getModule(PostModalModule);
  protected stickerModalModule = getModule(StickerModalModule);
  protected storesModule = getModule(StoresModule);
  protected uiModule = getModule(UiModule);
  protected userModule = getModule(UserModule);
  protected createModule = getModule(CreateModule);
  protected createAssetModule = getModule(CreateAssetModule);
  protected createPostModule = getModule(CreatePostModule);

  public get currentStore(): UserStore {
    return this.userModule.currentStore;
  }

  public get campaignSidebarOpen(): boolean {
    return this.uiModule.campaignSidebarOpen;
  }

  public set campaignSidebarOpen(value: boolean) {
    this.uiModule.toggleAltDrawer({
      drawerType: AltDrawerType.CampaignSidebar,
      open: value,
    });
  }

  public get isLoading(): boolean {
    return this.uiModule.isLoading;
  }

  public get mainNavigationDrawer(): boolean | null {
    return this.uiModule.mainNavigationDrawerOpen;
  }

  public get upcomingPostsSidebarOpen(): boolean | null {
    return this.uiModule.upcomingPostsSidebarOpen;
  }

  public get user(): ApplicationUser | null {
    return this.user;
  }

  /*
   * Check if the current store has authorised their social accounts.
   * If not, prompt them with a modal.
   * Pass if current user is an MSO and is not viewing a paricular store.
   */
  protected async checkSocialChannelsAuthorised() {
    /*
      this wait is a hack to resolve a race condition on page load where this.userModule.isViewingSingleStore is incorrect (stores haven't loaded yet?).
      this results in the below getStoreChannels() not being called which in turn disables buttons on recommended posts because store channels [] is empty
      @ 1 second the problem persists
    */
    if (this.userModule.currentStore.id === '') {
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    if (!this.userModule.isViewingSingleStore) {
      return;
    }

    await this.storesModule.getStoreChannels(this.userModule.currentStore.id);

    if (
      this.storesModule.storeChannels.length === 0 &&
      this.$route.name !== 'settings.root'
    ) {
      this.storesModule.toggleAuthoriseSocialChannelsModal(true);
    }
  }
}
